<template>
  <div class="wisdom-meteo">
    天气数据可视化
  </div>
</template>

<script>
  export default {
    name: 'MeteoDataVisualization'
  }
</script>

<style lang="scss" scoped>
  .wisdom-meteo {
    width: 1920px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-color: #666;
  }
</style>